import request from '../request'

//装卸司机列表
export const car_driver_index_request = p => {
    return request({
        method:'GET',
        url:'car_driver/index',
        params: p
    })
}

//装卸司机详情
export const car_driver_details_request = id => {
    return request({
        method:'GET',
        url:'car_driver/details',
        params: {
            id
        }
    })
}