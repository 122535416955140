<template>
    <div>
        <el-dialog
            title="司机详情"
            :visible.sync="dialogFormVisible"
            :append-to-body="true"
            width="60%"
            :center="true"
            top="20vh"
            :close-on-click-modal="false"
            :before-close="() => closeDialog()"
            v-loading.fullscreen.lock="login_loading"
        >
            <div>
                <el-form
                    :model="CarDriver"    
                    ref="CarDriverEditForm"
                    label-width="120px"
                    size="small"
                >
                    <div>
                        <el-descriptions
                            class="margin-top"
                            :column="2"
                            :labelStyle="{'width':'120px','text-align':'center'}"
                            :contentStyle="{'text-align': 'center'}"
                            border
                        >
                            <el-descriptions-item>
                                <template slot="label">
                                    <span>司机姓名</span>
                                </template>
                                <span>{{CarDriver.name}}</span>
                            </el-descriptions-item>
                            <el-descriptions-item>
                                <template slot="label">
                                    <span>手机号</span>
                                </template>
                                <span>{{CarDriver.phone}}</span>
                            </el-descriptions-item>
                            <el-descriptions-item>
                                <template slot="label">
                                    <span>身份证号</span>
                                </template>
                                <span>{{CarDriver.icard}}</span>
                            </el-descriptions-item>
                            <el-descriptions-item>
                                <template slot="label">
                                    <span>注册时间</span>
                                </template>
                                <span>{{CarDriver.create_time}}</span>
                            </el-descriptions-item>
                            <el-descriptions-item>
                                <template slot="label">
                                    <span>身份证正面</span>
                                </template>
                                <img
                                    v-if="CarDriver.icard_file_a[0]['url']"
                                    :src="CarDriver.icard_file_a[0]['url']"
                                    @click="showIMG(CarDriver.icard_file_a[0]['url'])"
                                    height="100px"
                                />
                            </el-descriptions-item>
                            <el-descriptions-item>
                                <template slot="label">
                                    <span>身份证反面</span>
                                </template>
                                <img
                                    v-if="CarDriver.icard_file_b[0]['url']"
                                    :src="CarDriver.icard_file_b[0]['url']"
                                    @click="showIMG(CarDriver.icard_file_b[0]['url'])"
                                    height="100px"
                                />
                            </el-descriptions-item>
                            <el-descriptions-item>
                                <template slot="label">
                                    <span>驾驶证主页</span>
                                </template>
                                <img
                                    v-if="CarDriver.jscard_file_a[0]['url']"
                                    :src="CarDriver.jscard_file_a[0]['url']"
                                    @click="showIMG(CarDriver.jscard_file_a[0]['url'])"
                                    height="100px"
                                />
                            </el-descriptions-item>
                            <el-descriptions-item>
                                <template slot="label">
                                    <span>驾驶证副页</span>
                                </template>
                                <img
                                    v-if="CarDriver.jscard_file_b[0]['url']"
                                    :src="CarDriver.jscard_file_b[0]['url']"
                                    @click="showIMG(CarDriver.jscard_file_b[0]['url'])"
                                    height="100px"
                                />
                            </el-descriptions-item>
                        </el-descriptions>
                        <!-- 图片放大 -->
                        <el-dialog
                            :visible.sync="dialogVisible"
                            :center="true"
                            :append-to-body="true"
                        >
                            <img width="100%" :src="dialogImageUrl" alt="">
                        </el-dialog>
                    </div>
                </el-form>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button
                    plain
                    @click="closeDialog()"
                    >关闭</el-button
                >
            </div>
        </el-dialog>
    </div>
</template>

<script>
export default {
    name: 'CarDriverEditComponent',
    data() {
        return {
            login_loading: false,
            CarDriver: {
                name: '',
                phone: '',
                icard: '',
                icard_file_a: [{name:'',url:''}],
                icard_file_b: [{name:'',url:''}],
                jscard_file_a: [{name:'',url:''}],
                jscard_file_b: [{name:'',url:''}]
            },
            dialogVisible: false,
            dialogImageUrl: ''
        }
    },
    computed: {},
    methods: {
        closeDialog() {
            this.CarDriver = {
                name: '',
                phone: '',
                icard: '',
                icard_file_a: [{name:'',url:''}],
                icard_file_b: [{name:'',url:''}],
                jscard_file_a: [{name:'',url:''}],
                jscard_file_b: [{name:'',url:''}]
            }
            this.$emit('exitDialog')
        },
        showIMG(url) {
            this.dialogVisible = true
            this.dialogImageUrl = url
        }
    },
    filters: {},
    props: {
        dialogFormVisible: Boolean,
        id: {
            type: Number,
            default() {
                return 0
            },
        },
        car_driver_details_data: {
            type: Object,
            default() {
                return {}
            },
        }
    },
    created() {},
    destoryed() {},
    mounted() {},
    updated() {},
    components: {},
    watch: {
        car_driver_details_data(newVal) {
            this.CarDriver = newVal
        },
    },
}
</script>

<style lang='less'>
.statec {
    width: 100%;
}
</style>