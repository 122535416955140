<template>
    <div v-loading.fullscreen.lock="loading">
        <car-driver-search-bar-component
            @search="get_car_driver_index"
        ></car-driver-search-bar-component>
        <common-table-component
            details_auth="装卸司机详情"
            :col_arr="fcol_arr"
            :table_data="ftable_data"
            @details_row="details_car_driver"
        ></common-table-component>
        <common-page-component
            :current_page="cond.page"
            :page_size="cond.limit"
            :total="ftotal"
            @search="get_car_driver_index"
        ></common-page-component>
        <car-driver-edit-component
            :id="fid"
            :dialogFormVisible="fdialogFormVisible"
            :car_driver_details_data="fcar_driver_details_data"
            @exitDialog="dialogExit"
            @search="get_car_driver_index"
            @details_row="details_car_driver"
        ></car-driver-edit-component>
    </div>
</template>

<script>
import { car_driver_index_request,car_driver_details_request } from "@/network/WareHouse/CarDriver.js"

import CarDriverSearchBarComponent from "@/components/WareHouse/CarDriver/CarDriverSearchBarComponent"
import CommonTableComponent from "@/components/common/CommonTableComponent"
import CommonPageComponent from "@/components/common/CommonPageComponent"
import CarDriverEditComponent from "@/components/WareHouse/CarDriver/CarDriverEditComponent"

export default {
    name: 'CarDriverView',
    data() {
        return {
            fid: 0,
            fdialogFormVisible: false,
            loading: false,
            ftotal: 0,
            cond: {
                name: '',
                phone: '',
                page: 1,
                limit: 10,
            },
            ftable_data: [],
            fcol_arr: [
                {
                    prop: 'name',
                    label: '司机姓名',
                    minWidth: '100px'
                },
                {
                    prop: 'phone',
                    label: '手机号',
                    minWidth: '120px',
                },
                {
                    prop: 'icard',
                    label: '身份证号',
                    minWidth: '150px',
                }
            ],
            fcar_driver_details_data: {}
        };
    },
    computed: {},
    methods: {
        get_car_driver_index(param = {}) {
            this.loading = true;
            this.cond.name = param.name ?? this.cond.name;
            this.cond.phone = param.phone ?? this.cond.phone;
            this.cond.icard = param.icard ?? this.cond.icard;
            this.cond.page = param.page ?? this.cond.page;
            if (param.limit) {
            this.cond.limit = param.limit;
            this.cond.page = 1;
            }
            car_driver_index_request(this.cond)
            .then((s) => {
                this.loading = false;
                if (s.status === 0) {
                this.ftable_data = s.result.data;
                this.ftotal = s.result.total;
                } else {
                this.$message.error(s.msg);
                }
            })
            .catch((err) => {
                this.loading = false;
                this.$message.error(err);
            });
        },
        details_car_driver(id) {
            this.fid = id;
            this.fdialogFormVisible = true;
            car_driver_details_request(id)
            .then((s) => {
                if (s.status === 0) {
                this.fcar_driver_details_data = s.result;
                } else {
                this.$message.error(s.msg);
                }
            })
            .catch((err) => {
                console.log(err);
            });
        },
        dialogExit() {
            this.fdialogFormVisible = false
            this.fid = 0
        }
    },
    filters: {},
    props: {},
    created() {
        this.get_car_driver_index()
    },
    destoryed() {},
    mounted() {},
    updated() {},
    components: {
        CarDriverSearchBarComponent,
        CommonTableComponent,
        CommonPageComponent,
        CarDriverEditComponent,
    },
    watch: {},
};
</script>

<style lang="less"></style>
